


$background-color: rgb(245, 244, 241);
$dark-bg:rgb(13, 47, 138);
$main-font-color:rgb(244, 201, 61);
$main-hover-bg:rgb(217, 200, 188);
$theme-border: black;
$theme-bg:rgb(219, 199, 157);
$hover-txt: rgb(100, 64, 10);
$dropdown-link-bg: #96643c;
$check-disabled:#f6d2d2;




@import url("https://fonts.googleapis.com/css2?family=Caveat&family=Comforter&family=Raleway&family=Tangerine&display=swap");

body {
    background-color: $background-color;

}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.provision-modal {
    width: 1000px;
    max-width: none;
}

.loader{
    color: $dark-bg;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}



.my-style {
    background-color: $dark-bg;
    color: $main-font-color;
    transition: 250ms;
    border: 1px solid black;
    width: 250px;
}

.style-btn-corner {
    background-color: $dark-bg;
    color: $main-font-color;
    transition: 250ms;
    border: 1px solid black;
    cursor: pointer;
}



.style-btn-corner-disabled {
    background-color: $dark-bg;
    color: $main-font-color;
    transition: 250ms;
    border: 1px solid black;
    opacity: 0.5;
    pointer-events: none;
}

.style-btn-corner:hover {

    background: $main-hover-bg;
    color: $theme-border;
}

.save-btn-corner {
    background-color: rgb(51, 124, 76);
    color: white;
    transition: 250ms;
    border: 1px solid green;
    cursor: pointer;
}

.save-btn-corner:hover {

    background: $main-hover-bg;
    color: $theme-border;
    border: none;
}

.btn:focus,
.btn:active,
.btn:hover {
    outline: none !important;
    box-shadow: none !important;
    background-color: $theme-bg;
    border: 1px solid grey;
    color: $hover-txt;
}

.delete-btn {
    background-color: rgb(212, 63, 63);
    transition: 250ms;
    color: white;
    cursor: pointer
}

.delete-btn:hover {
    background-color: rgb(231, 173, 173);
    color: red;
}

.dropdown-option {
    background-color: white;
    cursor: pointer;
    transition: 250ms;
}

.dropdown-option:hover {
    background-color: $main-hover-bg;

    color: $dark-bg;

    cursor: pointer
}

.heading {
    transition: 250ms;
    cursor: pointer;
}

.heading:hover {
    background-color: $main-hover-bg;

}

.row-even {
    background-color: $theme-bg;
    transition: 250ms;
    cursor: pointer;
}

.row-odd {
    background-color: whitesmoke;
    transition: 250ms;
    cursor: pointer;
}

.row-even-no-hover {
    background-color: $theme-bg;
    transition: 250ms;

}

.row-odd-no-hover {
    background-color: whitesmoke;
    transition: 250ms;

}

.row-even:hover {

    color: $main-font-color;
    background-color: $dark-bg;

    border: 1px solid $main-font-color;
    opacity: 1;

}

.row-odd:hover {
    color: $main-font-color;
    background-color: $dark-bg;
    /* transform: scale(1.05); */
    border: 1px solid $main-font-color;
    opacity: 1;
}



.half-modal {
    min-width: 50%;

}

.main-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1%;
}

.main-container-no-padding {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.main-banner {
    font-size: 3rem;

}

.small-banner {
    font-size: 2rem;

}

.nav-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    /* border: 1px solid black; */
    height: 50px;
    align-items: center;
}

.calendar-square {
    transition: 150ms;
    cursor: pointer;
}

.calendar-square-even {
    background-color: white;
    transition: 150ms;
    cursor: pointer;
}

.calendar-square-selected {
    background-color: rgb(65, 139, 82);
    font-weight: bold;
    transition: 150ms;
    cursor: pointer;
}

.calendar-square:hover {
    color: $main-font-color;
    background-color: $dark-bg;
    transform: scale(1.02);

    /* z-index:10; */
}

.calendar-square-selected:hover {
    /* background-color: $theme-bg; */
    transform: scale(1.02);
    /* z-index: 10; */
}

.calendar-not-possible {
    opacity: 0.5
}

.student-viewer-top {
    background-color: $background-color
}


.selector {
    /* background-color: $main-hover-bg; */
    display: flex;
    flex-direction: column;
    position: sticky;
    top: 70px;
    /* position:fixed; */
    /* border:1px solid black; */
    /* justify-content: space-around; */
}

.vertical-tab {
    transition: 250ms;
    border: 1px solid $main-hover-bg;
    color: black;
    background-color: $main-hover-bg;
    cursor: pointer;
    opacity: 0.75;
}

.vertical-tab:hover {
    color: $main-font-color;
    background-color: $dark-bg;

    border: 1px solid $main-font-color;
    opacity: 1;
}

.vertical-tab-selected {
    color: $main-font-color;
    background-color: $dark-bg;

    border: 1px solid $main-font-color;
    opacity: 1;
}

.DayPicker {
    display: inline-block;
    font-size: 1rem;
}

.DayPicker-wrapper {
    position: relative;

    flex-direction: row;
    padding-bottom: 1em;

    -webkit-user-select: none;

    -moz-user-select: none;

    -ms-user-select: none;

    user-select: none;
}

.DayPicker-Months {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.DayPicker-Month {
    display: table;
    margin: 0 1em;
    margin-top: 1em;
    border-spacing: 0;
    border-collapse: collapse;

    -webkit-user-select: none;

    -moz-user-select: none;

    -ms-user-select: none;

    user-select: none;
}

.DayPicker-NavButton {
    position: absolute;
    top: 1em;
    right: 1.5em;
    left: auto;

    display: inline-block;
    margin-top: 2px;
    width: 1.25em;
    height: 1.25em;
    background-position: center;
    background-size: 50%;
    background-repeat: no-repeat;
    color: #8b9898;
    cursor: pointer;
}

.DayPicker-NavButton:hover {
    opacity: 0.8;
}

.DayPicker-NavButton--prev {
    margin-right: 1.5em;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAVVJREFUWAnN2G0KgjAYwPHpGfRkaZeqvgQaK+hY3SUHrk1YzNLay/OiEFp92I+/Mp2F2Mh2lLISWnflFjzH263RQjzMZ19wgs73ez0o1WmtW+dgA01VxrE3p6l2GLsnBy1VYQOtVSEH/atCCgqpQgKKqYIOiq2CBkqtggLKqQIKgqgCBjpJ2Y5CdJ+zrT9A7HHSTA1dxUdHgzCqJIEwq0SDsKsEg6iqBIEoq/wEcVRZBXFV+QJxV5mBtlDFB5VjYTaGZ2sf4R9PM7U9ZU+lLuaetPP/5Die3ToO1+u+MKtHs06qODB2zBnI/jBd4MPQm1VkY79Tb18gB+C62FdBFsZR6yeIo1YQiLJWMIiqVjQIu1YSCLNWFgijVjYIuhYYCKoWKAiiFgoopxYaKLUWOii2FgkophYp6F3r42W5A9s9OcgNvva8xQaysKXlFytoqdYmQH6tF3toSUo0INq9AAAAAElFTkSuQmCC");
}

.DayPicker-NavButton--next {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAXRJREFUWAnN119ugjAcwPHWzJ1gnmxzB/BBE0n24m4xfNkTaOL7wOtsl3AXMMb+Vjaa1BG00N8fSEibPpAP3xAKKs2yjzTPH9RAjhEo9WzPr/Vm8zgE0+gXATAxxuxtqeJ9t5tIwv5AtQAApsfT6TPdbp+kUBcgVwvO51KqVhMkXKsVJFXrOkigVhCIs1Y4iKlWZxB1rX4gwlpRIIpa8SDkWmggrFq4IIRaJKCYWnSgnrXIQV1r8YD+1Vrn+bReagysIFfLABRt31v8oBu1xEBttfRbltmfjgEcWh9snUS2kNdBK6WN1vrOWxObWsz+fjxevsxmB1GQDfINWiev83nhaoiB/CoOU438oPrhXS0WpQ9xc1ZQWxWHqUYe0I0qrKCQKjygDlXIQV2r0IF6ViEBxVTBBSFUQQNhVYkHIVeJAtkNsbQ7c1LtzP6FsObhb2rCKv7NBIGoq4SDmKoEgTirXAcJVGkFSVVpgoSrXICGUMUH/QBZNSUy5XWUhwAAAABJRU5ErkJggg==");
}

.DayPicker-NavButton--interactionDisabled {
    display: none;
}

.DayPicker-Caption {
    display: table-caption;
    margin-bottom: 0.5em;
    padding: 0 0.5em;
    text-align: left;
}

.DayPicker-Caption>div {
    font-weight: 500;
    font-size: 1.15em;
}

.DayPicker-Weekdays {
    display: table-header-group;
    margin-top: 1em;
}

.DayPicker-WeekdaysRow {
    display: table-row;
}

.DayPicker-Weekday {
    display: table-cell;
    padding: 0.5em;
    color: #8b9898;
    text-align: center;
    font-size: 0.875em;
}

.DayPicker-Weekday abbr[title] {
    border-bottom: none;
    text-decoration: none;
}

.DayPicker-Body {
    display: table-row-group;
}

.DayPicker-Week {
    display: table-row;
}

.DayPicker-Day {
    display: table-cell;
    padding: 0.5em;
    /* border-radius: 50%; */
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
}

.DayPicker-WeekNumber {
    display: table-cell;
    padding: 0.5em;
    min-width: 1em;
    border-right: 1px solid #eaecec;
    color: #8b9898;
    vertical-align: middle;
    text-align: right;
    font-size: 0.75em;
    cursor: pointer;
}

.DayPicker--interactionDisabled .DayPicker-Day {
    cursor: default;
}

.DayPicker-Footer {
    padding-top: 0.5em;
}

.DayPicker-TodayButton {
    border: none;
    background-color: transparent;
    background-image: none;
    box-shadow: none;

    color: $main-hover-bg;
    font-size: 0.875em;
    cursor: pointer;
}

/* Default modifiers */

.DayPicker-Day--today {
    color: #d0021b;
    font-weight: 700;
}

.DayPicker-Day--outside {
    color: #8b9898;
    cursor: default;
}

.DayPicker-Day--disabled {
    color: #dce0e0;
    cursor: default;
    /* background-color: #eff1f1; */
}

/* Example modifiers */

.DayPicker-Day--sunday {
    background-color: #f7f8f8;
}

.DayPicker-Day--sunday:not(.DayPicker-Day--today) {
    color: #dce0e0;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    position: relative;
    border-color: black;
    background-color: $dark-bg; 
    color: $main-font-color;
    font-weight: bold;
    border-radius: 0;
    
    border-width: 1px;
    border-style: solid;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
    /* background-color: #51a0fa; */
    background-color: $dark-bg;
}

.DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
    background-color: $main-hover-bg;
    border-radius: 0;
}

/* DayPickerInput */

.DayPickerInput {
    display: inline-block;
}

.DayPickerInput-OverlayWrapper {
    position: relative;
}

.DayPickerInput-Overlay {
    position: absolute;
    left: 0;
    z-index: 1;

    background: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}

.ongoing-box {
    transition: 250ms;
}

.ongoing-box:hover {
    background-color: $main-hover-bg;
    color: $dark-bg;
}

.display-date:hover {
    background-color: $main-hover-bg;
}

.form-tab {
    transition: 250ms;
    border: 1px solid $main-hover-bg;
    color: black;
    background-color: $main-hover-bg;
    cursor: pointer;
    opacity: 0.75;
}

.form-tab:hover {
    color: $main-font-color;
    background-color: $dark-bg;
    /* transform: scale(1.05); */
    border: 1px solid black;
    opacity: 1;
}

.form-tab-selected {
    /* transform: scale(1.1); */
    transition: 250ms;
    color: $main-font-color;
    background-color: $dark-bg;
    border: 2px solid $main-font-color;
}


.table-header {
    border: 1px solid $main-hover-bg;
    color: $main-font-color;
    background-color: $dark-bg;


}

.table-row {
    border: 1px solid $main-hover-bg;
    color: $dark-bg;

}

.table-row-valid {
    background-color: rgb(99, 163, 120);
}

.table-row-invalid {
    background-color: rgb(240, 126, 126);
}


.checkbox {
    transition: 150ms;
    /* border: 1px solid black; */
}

.checkbox:hover {
    transform: scale(1.1);
    border: none;
    cursor: pointer;
}

.checkbox-selected {
    background-color: rgb(173, 233, 205);
    color: green;
}

.checkbox-unselected {
    background-color: rgb(228, 196, 196);
    color: red;
}

.drag-drop {
    background-color: $main-hover-bg;
    cursor: pointer;
}

.drag-drop:hover {
    background-color: rgb(187, 179, 179);
}

.form-input {
    height: 38px;
    border-radius: 3px;
    border: 1px solid grey;
}

.form-input:focus {
    outline: none;
    border: 2px solid $dark-bg;
    /* background-color: $main-hover-bg; */
}

.form-textarea {
    border-radius: 3px;
    border: 1px solid grey;
}

.form-textarea:focus {
    outline: none;
    border: 2px solid $dark-bg;
    /* background-color: $main-hover-bg; */
}

.form-textarea:hover {
    background-color: $main-hover-bg;
}

.form-input:hover {
    background-color: $main-hover-bg;
}

.row-valid {
    background-color: rgb(126, 186, 148);
    border: 1px solid green;
}

.row-invalid {
    background-color: rgb(232, 196, 196);
    border: 1px solid red;
}

.input-component {
    background-color: $background-color;
    padding: 1%;
    border-radius: 5px;
}

.delete-cross {
    color: red;
    transition: 250ms;
    cursor: pointer;
}

.delete-cross:hover {
    transform: scale(1.2);

}

.delete-box {
    color: white;
    background-color: #d95d5d;
    transition: 250ms;
    cursor: pointer;
}

.delete-box:hover {
    color: $main-font-color;
    background-color: #bd4242;
}

.zoom {
    transition: 150ms;
    cursor: pointer
}

.cancel {
    color: #d95d5d;
}

.zoom:hover {
    transform: scale(1.05);
    color: $main-font-color;
}


.zoom-file {
    transition: 150ms;
    color: $dark-bg;
    cursor: pointer;
    padding: 1%;
}

.zoom-file:hover {
    transform: scale(1.1);
    color: $main-font-color;

}

.ck-editor__editable {
    min-height: 150px;
}

.edit-mode-box {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70px;
    font-size: 2.5rem;
    color: $main-font-color;
    background-color: $dark-bg;

    gap: 3rem;

}

.table-row-highlighted {
    background-color: $main-hover-bg;
}

.large-modal {
    width: 95%;
    max-width: none;
}

@import url('https://fonts.googleapis.com/css2?family=Bad+Script&family=Square+Peg&display=swap');

.sideBar-wrap {
    background: $dark-bg;
    width: 100%;
    border-right: 1px solid $main-font-color;
}

.search-container {
    min-width: 60%;
    background-color: white;
    height: 100%;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.brand-container {
    min-width: 20%;
    background-color: #a09d98;
    height: 100%;
    flex-grow: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    font-family: 'Square Peg', cursive;

}

.nav-icon-bars {
    font-size: 2rem;
    height: 80px;
    display: flex;
    background-color: $dark-bg;
    align-items: center;
    color: white;
    flex-grow: 1;
    min-width: 20%;
    justify-content: center;
    padding-left: 0px;
}

.nav-main-icons {
    padding-left: 1rem;
    font-size: 1.5rem;
    height: 70px;
    display: flex;
    /* justify-content: flex-start; */
    align-items: center;
    color: white;
    flex-grow: 1;
}

.container-div {
    height: 100vh;
    margin-left: 15;
}

.sideBar-link {
    display: flex;

    /* justify-content: space-beteen; */
    align-items: center;
    padding: 20px;
    list-style: none;
    height: 50px;
    text-decoration: none;
    font-size: 16px;
    color: $main-font-color;
    transition: 350ms;
    font-family: 'Raleway', sans-serif;
    font-size: 1.2rem;
}

.sideBar-link:hover {
    background: $main-hover-bg;
    /* border-left: 4px solid $main-font-color; */
    cursor: pointer;
    color: $theme-border;
}

.email-link {
    display: flex;

    justify-content: flex-start;
    align-items: center;
    padding: 20px;
    list-style: none;
    height: 50px;
    text-decoration: none;

    color: $main-font-color;
    transition: 350ms;

    font-size: 1.2rem;
}

.email-link:hover {
    background: $main-hover-bg;
    /* border-left: 4px solid $main-font-color; */
    cursor: pointer;
    color: $theme-border;
}

.email-folder-link {
    display: flex;
    flex-grow: 1;
    justify-content: flex-start;
    align-items: center;
    padding: 20px;
    list-style: none;
    height: 40px;
    text-decoration: none;
    /* margin-left:10px; */
    color: $main-font-color;
    transition: 350ms;
    font-family: "Raleway";
    font-size: 1rem;
}

.email-folder-link:hover {
    background: $main-hover-bg;
    /* border-left: 4px solid $main-font-color; */
    cursor: pointer;
    color: $theme-border;
}

.dropDown-link {
    background: $dropdown-link-bg;
    font-family: 'Raleway', sans-serif;
    height: 60px;
    padding-left: 1.5rem;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: $main-font-color;
    font-size: 18px;
    text-decoration: none;
    gap: 0.6rem;
    transition: 350ms;
    border: none;

}

.dropDown-link:hover {
    cursor: pointer;
    background: $main-hover-bg;
    color: $theme-border;
}

.icon-container {
    display: flex;
    transition: 250ms;
}

.icon-container:hover {
    color: $main-font-color;
    cursor: pointer;
}

.sideBar-nav {
    width: 280px;
}

.brand-container {
    background-color: $dark-bg;
    color: $main-font-color
}

.brand-container:hover {
    color: $main-font-color;
    font-weight: 600;
}

.logout-container {
    background-color: $dark-bg;
    border-left: 1px solid $main-font-color;
    ;
}

.bars-container {
    background-color: $dark-bg;
}


.embed-container{
    height:80vh;
    width:1000px;
}

@media (max-width: 850px) {
    .sideBar-nav {
        width: 250px;
    }

    .nav-icon-bars {
        width: 15%;
        justify-content: center;
        padding-left: 0px;
    }

    .search-container {
        width: 85%;
    }

    .nav-main-icons {
        display: none;
    }
}

@media (max-width: 850px) {
    .half-modal {
        min-width: 80%;
    }
}



@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}